import React, { useState, useRef, useEffect, useMemo } from "react";
import Geohash from "latlon-geohash";
import { useParams } from "react-router";
import { useTheme } from "@mui/material";
import {
  checkedDeviceListArray,
  DeviceArrayLocationTypes,
  fetchedMappingData,
  fetchedMappingDataTypes,
  MapDataCSVTypes,
  mapDataPoints,
  MapDataPointTypes,
  MapDataTypes,
  MarkerArrayTypes,
  MarkerInstanceArrayTypes,
} from "depot/map-page.depot";
import moment from "moment";

import L, { Marker } from "leaflet";
import "leaflet-routing-machine";

import { CommonText } from "components/common/text/text.component";

import glowEffect from "assets/img/glow-effect.png";
import { getDateString, getColorMarker, getColorDot } from "utils/util-functions";
import { getDeviceMapPoints } from "api/MappingHelpers";
import { MAPBOX_STYLE_ID } from "utils/constants";

import { MapPageContainer, PageContent, PageTitle, RightWrapper, RouteToolsHelpContainer } from "./MapPage.style";
import { CENTER_POINT_LATITUDE, CENTER_POINT_LONGITUDE, DATA_FIELDS_STR } from "./Map.config";
import MapSidebar from "./components/MapSidebar/map-sidebar.component";
import { RouteTools } from "./components/route-tools/route-tools.component";

const MapPage = (): JSX.Element => {
  const [activeDevice, setActiveDevice] = useState<any>();

  // Below is ACTIVE, above does nothing
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [deviceDataObj, setDeviceDataObj] = useState<Record<string, MapDataTypes | null>>({});
  const [routeArray, setRouteArray] = useState<any[]>([]);
  const [showRoute, setShowRoute] = useState<boolean>(false);
  const [markerArray, setMarkerArray] = useState<MarkerArrayTypes[]>([]);
  const [displayHelpRouteText, setDisplayHelpRouteText] = useState<boolean>(false);
  const { palette } = useTheme();
  const [activeDeviceData, setActiveDeviceData] = useState<fetchedMappingDataTypes[]>([]);

  const { sn } = useParams();
  const mapRef = useRef<any>();
  const groupRef = useRef<any>();

  const [displayRouteTools, setDisplayRouteTools] = useState<boolean>(false);
  const [sliderStep, setSliderStep] = useState<number[]>([1, 1]);
  const [sliderTotalSteps, setSliderTotalSteps] = useState<number>(0);

  const deviceArray = useMemo<any>(() => {
    if (!deviceDataObj) return [];

    return Object.keys(deviceDataObj)
      .map((key) => deviceDataObj[key as keyof Record<string, MapDataTypes | null>])
      .filter((item) => !!item);
  }, [deviceDataObj]);

  useEffect(() => {
    if (deviceArray.length === 1 && deviceArray[0]?.locations?.length > 1) {
      setDisplayRouteTools(true);
      setSliderTotalSteps(deviceArray[0].locations.length);
      setSliderStep([1, deviceArray[0]?.locations?.length]);
      setDisplayHelpRouteText(false);
    } else if (deviceArray.length > 1) {
      setDisplayHelpRouteText(true);
      setDisplayRouteTools(false);
    } else {
      setDisplayHelpRouteText(false);
      setDisplayRouteTools(false);
    }
  }, [deviceArray, markerArray]);

  const addRouteToMap = (locations: { lat: number; lon: number }[], isActive: boolean) => {
    const waypoints = locations.map(({ lat, lon }) => L.latLng(lat, lon));

    // TODO HERE INSTANCES
    const instances = [];

    if (!process.env.REACT_APP_MAPBOX_ACCESS_TOKEN) {
      return;
    }

    for (let i = 0; i < waypoints.length - 1; i++) {
      const instance = L.Routing.control({
        router: L.Routing.mapbox(process.env.REACT_APP_MAPBOX_ACCESS_TOKEN, {}),
        waypoints: [waypoints[i], waypoints[i + 1]],
        lineOptions: {
          styles: isActive ? [{ color: "#EA4335", weight: 5, zIndex: 100 }] : [{ color: "#6FA1EC", weight: 3 }],
        },
        show: false,
        addWaypoints: false,
        routeWhileDragging: false,
        draggableWaypoints: false,
        fitSelectedRoutes: false,
        showAlternatives: false,
        createMarker: function () {
          return null;
        },
      });
      if (showRoute) {
        instance.addTo(mapRef?.current);
      }
      instances.push(instance);
    }
    return instances;
  };

  useEffect(() => {
    const mapDepotData = mapDataPoints.observable.subscribe({
      next: (depotData: MapDataPointTypes) => {
        const serialNumber: string = depotData.sn;
        const newObj: Record<string, MapDataTypes | null> = deviceDataObj;
        if (!serialNumber) {
          return;
        }

        newObj[serialNumber as keyof MapDataPointTypes] = depotData.mapData;

        setDeviceDataObj({ ...newObj });
      },
    });

    const fetchedMapDepotData = fetchedMappingData.observable.subscribe({
      next: (data: fetchedMappingDataTypes[]) => {
        setActiveDeviceData(data);
      },
    });

    if (sn?.length !== undefined && sn?.length > 0) {
      checkedDeviceListArray.setData([sn]);
      getDeviceMapPoints(sn, DATA_FIELDS_STR, true);
    }

    if (!mapRef.current) {
      mapRef.current = L.map("map-container").setView([CENTER_POINT_LONGITUDE, CENTER_POINT_LATITUDE], 5);

      L.tileLayer(
        `https://api.mapbox.com/styles/v1/mapbox/${MAPBOX_STYLE_ID}/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`,
        {
          attribution: 'Imagery &copy; <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 19,
        },
      ).addTo(mapRef.current);

      // create feature group that will contains all markers
      groupRef.current = L.featureGroup().addTo(mapRef.current);
    }

    return () => {
      window.sessionStorage.removeItem("activeDevice");
      mapDepotData.unsubscribe();
      fetchedMapDepotData.unsubscribe();
    };
  }, []);

  const addRemoveRoutes = () => {
    // if route is not in deviceArray, then remove it!

    const newRoutes = routeArray.filter(
      ({ serialNumber, routeInstance, visible }: { serialNumber: string; routeInstance: any; visible: boolean }) => {
        const result: any = deviceArray.find(
          ({ serialNumber: serial }: { serialNumber: string }) => serial === serialNumber,
        );
        if (!result) {
          if (visible) {
            try {
              routeInstance?.setWaypoints([]);

              if (mapRef !== undefined) {
                mapRef?.current?.removeControl(routeInstance);
              }
            } catch {
              // placeholder
            }
          }
        }
        return !!result;
      },
    );

    // if device is not in route array, then add it!
    deviceArray.forEach(({ locations, sn: serialNumber }: { locations: DeviceArrayLocationTypes[]; sn: any }) => {
      const result = newRoutes.find(({ serialNumber: serial }: { serialNumber: string }) => serial === serialNumber);

      if (!result) {
        const instances: any = addRouteToMap(locations, serialNumber === activeDevice);

        instances.forEach((instance: any) => {
          return newRoutes.push({
            serialNumber,
            visible: showRoute,
            routeInstance: instance,
          });
        });
      }
    });

    setRouteArray(newRoutes);
  };

  const markerPopup = (lat: number, lon: number, time: string, color: string, rsrp: number) => `
    <div>
      <div>Date: ${getDateString(time)}</div>
      <div>Latitude: ${lat}</div>
      <div>Longitude: ${lon}</div>
      <div>
        RSRP: <span style="color: ${color}">${rsrp === 0 ? "" : rsrp}</span>
      </div>
    </div>
  `;

  const createCustomIcon = (index: number, color: string | undefined, serialNumber: string) =>
    index === 0
      ? L.icon({
          iconUrl: getColorMarker(color),
          shadowUrl: serialNumber === activeDevice ? glowEffect : null,
          shadowSize: [70, 70],
          shadowAnchor: [35, 60],
          iconSize: [15, 29],
          iconAnchor: [7, 29],
          popupAnchor: [0, -30],
        })
      : L.icon({
          iconUrl: getColorDot(color),
          shadowUrl: serialNumber === activeDevice ? glowEffect : null,
          shadowSize: [70, 70],
          shadowAnchor: [35, 35],
          iconSize: [15, 15],
          iconAnchor: [7, 7],
          popupAnchor: [0, -5],
        });

  const addRemoveMarkers = () => {
    const array = [...markerArray];

    // if marker is not in deviceArray, then remove it!
    const newRoutes: any = array.filter(
      ({ serialNumber, markerInstanceArray }: { serialNumber: string; markerInstanceArray: any[] }) => {
        const result = deviceArray.find(
          ({ serialNumber: serial }: { serialNumber: string }) => serial === serialNumber,
        );

        if (!result && markerInstanceArray.length > 0) {
          markerInstanceArray.forEach((markerInstance: { options: { opacity: number } }) => {
            if (markerInstance.options.opacity && markerInstance) {
              groupRef?.current?.removeLayer(markerInstance);
            }
          });
        }

        return !!result;
      },
    );

    // if device is not in marker array, then add it!
    deviceArray.forEach(({ locations, sn: serialNumber }: { locations: MarkerInstanceArrayTypes[]; sn: string }) => {
      const result: any = newRoutes.find(
        ({ serialNumber: serial }: { serialNumber: string }) => serial === serialNumber,
      );
      if (!result && groupRef?.current) {
        const markerInstanceArray: Marker<any>[] = locations.map(
          ({ time, rsrp, lat, lon, color }: MarkerInstanceArrayTypes, index: number) => {
            const customIcon = createCustomIcon(index, color, serialNumber);
            const marker = L.marker([lat, lon], { icon: customIcon, title: `${index + 1}` }).addTo(groupRef.current);

            const popupContent = markerPopup(lat, lon, time, color, rsrp);

            // Add click event handler on marker
            marker.on("click", () => {
              mapRef.current?.flyTo([lat, lon], mapRef.current?.getZoom(), {
                animate: true,
                duration: 1,
              });

              marker.unbindPopup().bindPopup(popupContent).openPopup();
            });

            return marker;
          },
        );

        newRoutes.push({
          serialNumber,
          markerInstanceArray,
        });
      }
    });

    setMarkerArray(newRoutes);
  };

  useEffect(() => {
    // if selected devices are changed, then add/remove markers & routes!
    // This should be done using JavaScript
    addRemoveRoutes();
    addRemoveMarkers();
  }, [deviceArray]);

  const exportCsv = (deviceLocationData: MapDataCSVTypes[]) => {
    const timeStamp = new Date();
    const combinedLabels = [
      "Type",
      "Serial_Number",
      "IMEI",
      "Phone_Number",
      "Device_Alias",
      "DateTime_GPS",
      "RSSI",
      "RSRP",
      "LAT",
      "LONG",
    ];
    const csvDataLabels = `${combinedLabels}\n`;
    const deviceLocationStrings: string = deviceLocationData
      .map((row: MapDataCSVTypes) => Object.values(row).join(","))
      .join("\n");

    const hiddenElement = document.createElement("a");

    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(csvDataLabels + deviceLocationStrings)}`;
    hiddenElement.target = "_blank";
    hiddenElement.download = `MegaFi_${timeStamp}.csv`;
    hiddenElement.click();
  };

  const getMapDeviceData = () => {
    // filter data points determined by map slider
    const firstStep = sliderStep[0] - 1;
    const lastStep = sliderStep[1];

    if (activeDeviceData && activeDeviceData.length) {
      activeDeviceData.map(({ dataPoints, device }: fetchedMappingDataTypes) => {
        const locationPoints = [...dataPoints].sort((a, b) => a.timeStamp - b.timeStamp);
        const deviceLocationDataPoints: MapDataCSVTypes[] = locationPoints
          .map(({ geohash, rssi, rsrp, timeStamp }) => ({
            type: "Map data points",
            sn: `${device.sn}\t`,
            imei: `${device.imei}\t`,
            ph: `${device.ph}\t`,
            al: `${device.al}`,

            // Excel reformats time stamp to scientific notication. The equation below corrects this.
            DateTime_GPS: timeStamp / 86400000 + 25569,
            rssi: `${rssi}`,
            rsrp: `${rsrp}`,
            ...(geohash ? Geohash.decode(geohash) : {}),
          }))
          .slice(firstStep, lastStep);

        return exportCsv(deviceLocationDataPoints);
      });
    }
  };

  useEffect(() => {
    const bounds = groupRef?.current?.getBounds();
    if (bounds && Object.keys(bounds).length > 0) {
      mapRef?.current?.fitBounds(groupRef?.current?.getBounds());
    }
  }, [groupRef?.current, deviceArray]);

  const handleStepChange = (value: number[]) => {
    const startChanged = sliderStep[0] !== value[0];
    setSliderStep(value);
    if (markerArray && markerArray.length > 0) {
      markerArray[0].markerInstanceArray.forEach((marker: any, index: any) => {
        const isMarkerVisible = index >= value[0] - 1 && index <= value[1] - 1;

        if (isMarkerVisible) {
          if (!marker.options.opacity) {
            marker.setOpacity(1);
            marker.addTo(groupRef?.current);
          }
        } else {
          marker.setOpacity(0);
          groupRef?.current?.removeLayer(marker);
        }

        const isRouteVisible = index >= value[0] - 1 && index < value[1] - 1 && showRoute;
        if (routeArray[index]) {
          if (isRouteVisible) {
            if (!routeArray[index].visible) {
              routeArray[index].routeInstance.addTo(mapRef?.current);
              routeArray[index].visible = true;
            }
          } else {
            mapRef?.current.removeControl(routeArray[index].routeInstance);
            routeArray[index].visible = false;
          }
        }
      });
    }
    const changedIndex = startChanged ? value[0] - 1 : value[1] - 1;

    if (deviceArray[0]?.locations[changedIndex]) {
      const { lat, lon }: { lat: number; lon: number } = deviceArray[0].locations[changedIndex];
      mapRef.current?.flyTo([lat, lon], mapRef.current?.getZoom(), {
        animate: true,
        duration: 1,
      });
    }
  };

  useEffect(() => {
    if (displayRouteTools && showRoute) {
      handleStepChange(sliderStep);
    }
  }, [displayRouteTools, showRoute]);

  const toggleDeviceRoute = () => {
    if (showRoute) {
      setShowRoute(false);
      // HIDE
      routeArray.forEach((route) => {
        if (route.visible) {
          mapRef?.current.removeControl(route.routeInstance);
          // eslint-disable-next-line
          route.visible = false;
        }
      });
    } else {
      // SHOW VALID ONLY
      setShowRoute(true);
      // SHOW ALL
      if (!displayRouteTools) {
        routeArray.forEach((route) => {
          if (!route.visible) {
            route.routeInstance.addTo(mapRef?.current);
            // eslint-disable-next-line
            route.visible = true;
          }
        });
      }
    }
  };

  const valueLabelFormat = (value: any) => {
    try {
      if (deviceArray[0]) {
        const date = moment(deviceArray[0].locations[value - 1].timeStamp).format("MM/DD/YY h:mm A");
        return `${value} (${date})`;
      }
      return "";
    } catch (error) {
      console.warn("error", error);
    }
  };

  return (
    <MapPageContainer>
      <PageTitle>Map</PageTitle>

      <PageContent>
        <div>
          <MapSidebar
            showDrawer={showDrawer}
            onCloseDrawer={() => setShowDrawer(false)}
            dataFieldStr={DATA_FIELDS_STR}
          />
        </div>

        <RightWrapper className={palette.mode}>
          {displayHelpRouteText && (
            <RouteToolsHelpContainer>
              <CommonText>
                Route tools can only be displayed when a single device is selected and contains mapped location points.
              </CommonText>
            </RouteToolsHelpContainer>
          )}

          <div
            id="map-container"
            style={{
              height: "98.6%",
              width: "100%",
            }}
          />

          {displayRouteTools && (
            <>
              <RouteTools
                sliderStep={sliderStep}
                sliderTotalSteps={sliderTotalSteps}
                onChange={handleStepChange}
                toggleDeviceRoute={toggleDeviceRoute}
                valueLabelFormat={valueLabelFormat}
                getMapDeviceData={getMapDeviceData}
              />
            </>
          )}
        </RightWrapper>
      </PageContent>
    </MapPageContainer>
  );
};

export default MapPage;
