import { useForm as useHookForm } from "react-hook-form";
import { useMemo, useCallback } from "react";
import { ButtonText } from "components/common/Buttons";
import useConfirm from "hooks/useConfirm";
import FormFactory from "./FormFactory";

const useForm = ({ defaultValues, isEditing, onSave, onCancel }) => {
  const { register, handleSubmit, control, reset, formState, watch } = useHookForm({
    defaultValues,
  });
  const { errors, dirtyFields } = formState;

  const [getConfirmation, Confirmation] = useConfirm();

  const handleCancel = useCallback(async () => {
    if (dirtyFields && Object.keys(dirtyFields).length > 0) {
      const isConfirmed = await getConfirmation({
        title: "Form has unsaved changes",
        content: "Are you sure you want to proceed?",
        buttonTxt: ButtonText.Proceed,
      });

      if (isConfirmed) {
        reset();
        onCancel?.();
      }
    } else {
      reset();
      onCancel?.();
    }
  }, [dirtyFields, onCancel, reset, getConfirmation]);

  const handleSave = useCallback(
    (data) => {
      onSave?.(data).then((updateValues) => {
        reset(updateValues);
      });
    },
    [onSave, reset],
  );

  const formFactory = useMemo(
    () => FormFactory.getInstance({ handleCancel, handleSubmit, onSave: handleSave, isEditing, Confirmation }),
    [handleCancel, Confirmation, handleSubmit, handleSave, isEditing],
  );

  return {
    register,
    control,
    errors,
    reset,
    watch,
    Form: formFactory.Form,
  };
};

export default useForm;
