/* eslint-disable react/prop-types */
import React, { createContext, useState, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useProvideAuth } from "hooks/useProvideAuth";
import { COLOR_MODE } from "utils/constants";
import { DARK_PALETTE, LIGHT_PALETTE } from "utils/theme";

const GlobalContext = createContext({ toggleColorMode: () => {}, colorMode: COLOR_MODE.LIGHT, auth: {} });

const ContextProvider = ({ children }) => {
  const [isDirectFromLogin, setIsDirectFromLogin] = useState(false);
  const [mode, setMode] = useState(COLOR_MODE.LIGHT);
  const auth = useProvideAuth();

  const contextValue = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === COLOR_MODE.LIGHT ? COLOR_MODE.DARK : COLOR_MODE.LIGHT));
      },
      colorMode: mode,
      auth,
      isDirectFromLogin,
      setIsDirectFromLogin,
    }),
    [mode, auth],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === COLOR_MODE.LIGHT ? LIGHT_PALETTE : DARK_PALETTE),
        },
        components: {
          MuiTypography: {
            variants: [
              {
                props: { variant: "subtitle1" },
                style: {
                  color: "var(--mega-color-gray500)",
                },
              },
              {
                props: { variant: "subtitle2" },
                style: {
                  color: "var(--mega-color-gray400)",
                  fontWeight: 700,
                },
              },

              {
                props: { variant: "body1" },
                style: {
                  fontWeight: 400,
                  fontSize: "16px",
                },
              },
            ],
            styleOverrides: {
              root: {
                lineSpacing: 0,
                letterSpacing: 0,
                color: "var(--mega-color-gray)",
              },
            },
          },

          MuiIconButton: {
            styleOverrides: {
              root: {
                disableRipple: true,
                disableRippleFocus: true,
              },
            },
          },

          MuiTableCell: {
            styleOverrides: {
              root: {
                padding: "12px",
                "& .MuiTableCell-sizeSmall": {
                  width: "100px",
                  columnWidth: "200px",
                },
              },
            },
          },

          MuiTableRow: {
            styleOverrides: {
              root: {
                "&.MuiTableRow-hover:hover": {
                  cursor: "pointer",
                },
              },
            },
          },

          MuiDataGrid: {
            styleOverrides: {
              root: {
                "& .MuiDataGrid-row:hover": {
                  backgroundColor: "inherit", // Or 'transparent' or whatever color you'd like
                },
              },
            },
          },

          MuiButtonBase: {
            styleOverrides: {
              root: {
                disableRipple: true,
              },
            },
          },
        },
      }),
    [mode],
  );

  return (
    <GlobalContext.Provider value={contextValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </GlobalContext.Provider>
  );
};

export { GlobalContext, ContextProvider };
