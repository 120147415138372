import React, { useState, useMemo, useEffect, BaseSyntheticEvent } from "react";
import TableRow from "@mui/material/TableRow";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FieldValues, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ListItemIcon, ListItemText, useTheme } from "@mui/material";

import useConfirm from "hooks/useConfirm";
import { NxtIconButton } from "components/common/nxt-icon-button/nxt-icon-button.component";
import { PageTitle } from "components/common/Text";
import { TableBodyCell } from "components/table/table.style";
import NewAdminDialog from "components/power-user/new-admin-dialog.component";
import { PageFooter } from "components/common/Wrapper";
import { getCurrentPage, getFilteredListNew, getSortList, nameValidationCheck } from "utils/util-functions";
import { FieldError, StyledTextField } from "components/common/Input";
import { PrimaryButton, GhostButton, ButtonText } from "components/common/Buttons";
import CustomTable from "components/table/table.component";
import { addRemoveOrg, OrgValueTypes } from "api/PowerUserHelpers";
import { useAuth } from "hooks/useProvideAuth";
import TableFilterNew from "components/table/TableFilterNew.component";
import { showSuccess } from "utils/notifications";
import useOrganizations from "hooks/useOrganizations";
import ORG_COLUMNS, { ORG_COLUMN_TYPES, orgTableRowDefaults, OrgTableRowTypes } from "./Organizations.config";
import style from "./organizations.module.css";

const OrganizationsPage = (): JSX.Element => {
  const [isCreatingOrg, setIsCreatingOrg] = useState<boolean>(false);
  const [isOpenNewOrgModal, setIsOpenNewOrgModal] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [filterColumn, setFilterColumn] = useState<string>("");
  const [filterWord, setFilterWord] = useState<string>("");
  const [filterType, setFilterType] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [sortBy, setSortBy] = useState<string>("");

  const { sub: userId }: Record<string, string> = useAuth();
  const theme = useTheme();
  const [getConfirmation, Confirmation] = useConfirm();

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState<OrgTableRowTypes>(orgTableRowDefaults);
  const [isOpenNewAdminModal, setIsOpenNewAdminModal] = useState(false);
  const open = Boolean(anchorEl);

  const [reloadOrg, setReloadOrg] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState("");
  const { isLoading, orgList } = useOrganizations(userId, reloadOrg);

  useEffect(() => {
    if (!isLoading) {
      setReloadOrg(false);
    }
  }, [isLoading]);

  const filteredList = useMemo(
    () => getFilteredListNew(orgList, filterColumn, filterType, filterWord),
    [orgList, filterColumn, filterWord, filterType],
  );
  const sortedList = useMemo(() => {
    const sortColumn = ORG_COLUMNS.find(({ id }) => id === sortBy);
    return getSortList(filteredList, sortBy, sortOrder, sortColumn?.type);
  }, [sortBy, sortOrder, filteredList]);
  const paginatedList = useMemo(() => getCurrentPage(sortedList, page, rowsPerPage), [sortedList, page, rowsPerPage]);
  const totalOrgCount = useMemo(() => filteredList?.length || 0, [filteredList]);

  const filterColumns = ORG_COLUMNS.filter(({ filter }) => filter);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  }: FieldValues = useForm();

  const orgColumns = useMemo(() => {
    const tempCols = [...ORG_COLUMNS];
    tempCols.pop();
    return tempCols;
  }, []);

  const onNewOrganization = () => {
    setValue("orgName", "");
    setIsOpenNewOrgModal(true);
  };

  const onModalClose = (event: never, reason: string) => {
    if (reason !== "backdropClick") {
      setIsOpenNewOrgModal(false);
    }
  };

  const closeModal = () => {
    setIsOpenNewOrgModal(false);
  };

  const onCreateNewOrg = async ({ orgName }: FieldValues) => {
    try {
      const body: OrgValueTypes = {
        username: userId,
        groupname: orgName,
        action: "add",
      };
      setIsCreatingOrg(true);

      const { data: response } = await addRemoveOrg(body);
      showSuccess(response.message);
      setIsOpenNewOrgModal(false);
    } catch (error) {
      // error handling
    } finally {
      setIsCreatingOrg(false);
      setReloadOrg(true);
    }
  };

  const onClickMenu = (event: BaseSyntheticEvent, row: OrgTableRowTypes) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const onMenuClose = () => {
    setAnchorEl(null);
    setCurrentRow(orgTableRowDefaults);
  };

  const onNewAdmin = () => {
    setIsOpenNewAdminModal(true);
  };

  const onDeleteOrg = async () => {
    setAnchorEl(null);

    const isConfirm = await getConfirmation({
      title: "Delete Organization",
      content: "Are you sure that you want to delete the selected organization?",
      buttonTxt: ButtonText.Delete,
    });

    if (isConfirm) {
      const body = {
        username: userId,
        groupname: currentRow?.GroupName,
        action: "delete",
      };

      try {
        const { data: response } = await addRemoveOrg(body);
        setReloadOrg(true);
        showSuccess(response.message);
      } catch (error) {
        // error handling
      }
    }
  };

  const onSearch = (column: string, columnType: string, searchContent: string) => {
    setFilterColumn(column);
    setFilterWord(searchContent);
    setFilterType(columnType);
    setPage(0);
  };

  const onResetFilter = () => {
    setFilterColumn("");
    setFilterWord("");
    setFilterType("");
    setPage(0);
  };

  const handleRequestSort = (order: string, orderBy: string) => {
    setSortOrder(order);
    setSortBy(orderBy);
  };

  return (
    <>
      <PageTitle>Organizations</PageTitle>
      <div className={style.barContainer}>
        <div className={style.filterContainer}>
          {orgList?.length > 5 ? (
            <TableFilterNew columns={filterColumns} onSearch={onSearch} onReset={onResetFilter} />
          ) : (
            <div />
          )}
        </div>
        <div className={style.buttonOptions}>
          <NxtIconButton icon={<AddCircleOutlineIcon />} text="New Organization" onClick={onNewOrganization} />
        </div>
      </div>
      <CustomTable
        boxShadow
        rowsPerPageOptions={[10, 25, 100]}
        totalCount={totalOrgCount}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={(pageNum) => setPage(pageNum)}
        onRowsPerPageChange={(rowPerPage) => setRowsPerPage(rowPerPage)}
      >
        <CustomTable.Header columns={ORG_COLUMNS} onRequestSort={handleRequestSort} />

        <CustomTable.Body isLoading={isLoading} deviceData={paginatedList}>
          {paginatedList &&
            paginatedList.map(
              (row: OrgTableRowTypes) =>
                row && (
                  <TableRow key={row.GroupName}>
                    {orgColumns.map((column: ORG_COLUMN_TYPES) => {
                      const value: string = row[column.id as keyof OrgTableRowTypes];

                      return (
                        <TableBodyCell key={column.id}>{column.format ? column.format(value) : value}</TableBodyCell>
                      );
                    })}
                    <TableBodyCell style={{ width: 30 }}>
                      <Button onClick={(e) => onClickMenu(e, row)} style={{ color: theme.palette.common.black }}>
                        <MoreVertIcon />
                      </Button>
                    </TableBodyCell>
                  </TableRow>
                ),
            )}
        </CustomTable.Body>
      </CustomTable>

      <Menu
        anchorEl={anchorEl}
        onClick={() => setSelectedOrg(currentRow?.GroupName)}
        open={open}
        onClose={onMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={onNewAdmin}>
          <ListItemIcon>
            <AddCircleOutlineIcon />
          </ListItemIcon>
          <ListItemText>New Admin</ListItemText>
        </MenuItem>
        <MenuItem onClick={onDeleteOrg}>
          <ListItemIcon>
            <DeleteOutlineIcon />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <Dialog fullWidth maxWidth="xs" open={isOpenNewOrgModal} onClose={onModalClose} disableEscapeKeyDown>
        <DialogTitle>New Organization</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <StyledTextField
              label="Organization Name"
              {...register("orgName", {
                required: "Organization Name is required",
                validate: {
                  nameValidationCheck: (name: string, other: string) => nameValidationCheck(name, other, true, true),
                },
              })}
              error={errors.orgName}
            />
            {errors.orgName && <FieldError>{errors.orgName.message}</FieldError>}

            <PageFooter>
              <GhostButton onClick={closeModal}>{ButtonText.Cancel}</GhostButton>
              <PrimaryButton onClick={handleSubmit(onCreateNewOrg)} disabled={isCreatingOrg}>
                {ButtonText.Create}
              </PrimaryButton>
            </PageFooter>
          </Box>
        </DialogContent>
      </Dialog>

      <NewAdminDialog
        open={isOpenNewAdminModal}
        userId={userId}
        selectedOrg={selectedOrg}
        onClose={() => setIsOpenNewAdminModal(false)}
        onAdd={() => {}}
      />

      <Confirmation />
    </>
  );
};

export default OrganizationsPage;
