import React, { useContext, useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { CommonText } from "components/common/text/text.component";
import DialogTitle from "@mui/material/DialogTitle";
import { onEnter } from "utils/general";
import Box from "@mui/material/Box";
import { useSWRConfig } from "swr";
import { Card } from "@mui/material";
import { FormInput, FieldError, StyledTextField } from "components/common/Input";
import { UserListTypes } from "pages/Users/users.config";
import { AuthButton, TextButton, GhostButton, ButtonText } from "components/common/Buttons";
import LogoBlue from "assets/svg/nextivity_logo_color.svg";
import { showError } from "utils/notifications";
import { emailRegex } from "utils/constants";
import { passwordStrengthCheck } from "utils/util-functions";
import { useAuth } from "hooks/useProvideAuth";
import { GlobalContext } from "context/GlobalContext";
import { PageFooter } from "components/common/Wrapper";
import { FormWrapper, NewMember, PageContainer, LogoImg, AccountInfo } from "./Login.style";
import { authenticateUser } from "../Cogcognito";
import LOGIN_STEP from "./Login.config";

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(false);
  const [isMfaLoading, setIsMfaLoading] = useState<boolean>(false);
  const [isNewPwdLoading, setIsNewPwdLoading] = useState<boolean>(false);
  const [loginStep, setLoginStep] = useState<string>(LOGIN_STEP.AUTH);
  const [mfaParam, setMfaParam] = useState({});
  const [newPasswordParam, setNewPasswordParam] = useState({});
  const [isOpenAccountModal, setIsOpenAccountModal] = useState<boolean>(false);

  const { setIsDirectFromLogin }: any = useContext(GlobalContext);

  const { refetch }: any = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  }: FieldValues = useForm({});

  const {
    register: registerMfa,
    handleSubmit: handleSubmitMfa,
    formState: { errors: mfaErrors },
  }: FieldValues = useForm({});

  const {
    register: registerNewPassword,
    handleSubmit: handleSubmitNewPassword,
    formState: { errors: newPasswordErrors },
    watch,
  }: FieldValues = useForm({});
  const newPassword = watch("newPassword", "");

  const { cache }: any = useSWRConfig();

  useEffect(() => {
    cache.clear();
  }, []);

  const mfaCallback = (cognitoUser: any, mfaFunction: any) => {
    setIsAuthLoading(false);

    setMfaParam({ cognitoUser, mfaFunction });
    setLoginStep(LOGIN_STEP.MFA);
  };

  const newPasswordCallback = (cognitoUser: any, userAttributes: any, newPasswordFunction: any) => {
    setIsAuthLoading(false);

    setNewPasswordParam({ cognitoUser, userAttributes, newPasswordFunction });
    setLoginStep(LOGIN_STEP.NEW_PASSWORD);
  };

  const onSubmit = (data: FieldValues) => {
    const { email, password } = data;

    setIsAuthLoading(true);

    authenticateUser(
      email,
      password,
      (
        err: { name: string },
        result: {
          getIdToken: () => { (): any; new (): any; getJwtToken: { (): string; new (): any } };
          getAccessToken: () => { (): any; new (): any; getJwtToken: { (): string; new (): any } };
        },
      ) => {
        setIsAuthLoading(false);
        setIsMfaLoading(false);
        setIsNewPwdLoading(false);

        if (err) {
          if (err.name === "UserNotConfirmedException") {
            localStorage.setItem("destinationForVerify", email);
            navigate("/verify-email");
          } else {
            showError(err);
          }
          return;
        }

        localStorage.setItem("apiFailCount", String(0));
        localStorage.setItem("id", result.getIdToken().getJwtToken());
        localStorage.setItem("access", result.getAccessToken().getJwtToken());

        const localId: string | null = localStorage.getItem("id");

        if (!localId) {
          return;
        }

        const decoded: UserListTypes = jwtDecode(localId);
        if (!localId) {
          return;
        }
        if (!decoded?.email_verified) {
          localStorage.setItem("destinationForVerify", decoded.email);
          navigate("/verify-email");
        } else {
          refetch?.()
            .then(({ isAdmin, isPowerUser }: { isAdmin: boolean; isPowerUser: boolean }) => {
              setIsDirectFromLogin(true);
              navigate(isPowerUser ? "/orgs" : isAdmin ? "/users" : "/devices");
            })
            .catch((error: { toString: () => any }) => {
              showError(error.toString());
            });
        }
      },
      mfaCallback,
      newPasswordCallback,
    );
  };

  const onSubmitMfa = (data: FieldValues) => {
    const { mfaCode } = data;
    const { cognitoUser, mfaFunction }: any = mfaParam;

    if (cognitoUser && mfaFunction) {
      setIsMfaLoading(true);
      cognitoUser.sendMFACode(mfaCode, mfaFunction);
    }
  };

  const onSubmitNewPassword = (data: FieldValues) => {
    const { newPassword: newPwd } = data;

    const { cognitoUser, userAttributes, newPasswordFunction }: any = newPasswordParam;
    if (cognitoUser && userAttributes && newPasswordFunction) {
      setIsNewPwdLoading(true);

      delete userAttributes.email;
      delete userAttributes.phone_number;
      cognitoUser.completeNewPasswordChallenge(newPwd, userAttributes, newPasswordFunction);
    }
  };

  const showAccountInfo = () => {
    setIsOpenAccountModal(true);
  };

  const onModalClose = (event: never, reason: string) => {
    if (reason !== "backdropClick") {
      setIsOpenAccountModal(false);
    }
  };

  return (
    <PageContainer>
      {loginStep === LOGIN_STEP.AUTH && (
        <FormWrapper onSubmit={handleSubmit((data: FieldValues) => onSubmit(data))}>
          <LogoImg src={LogoBlue} alt="logo" />
          <StyledTextField
            onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmit(onSubmit))}
            label="Email"
            {...register("email", {
              required: "Email is required!",
              pattern: {
                value: emailRegex,
                message: "Entered value does not match email format!",
              },
            })}
            error={errors.email}
          />
          {errors.email && <FieldError>{errors.email.message}</FieldError>}

          <StyledTextField
            onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmit(onSubmit))}
            label="Password"
            type="password"
            {...register("password", {
              required: "Password is required!",
            })}
            style={{ marginTop: 20 }}
            error={errors.password}
          />
          {errors.password && <FieldError>{errors.password.message}</FieldError>}

          <AuthButton disabled={isAuthLoading} onClick={handleSubmit(onSubmit)} sx={{ mt: 2 }}>
            {ButtonText.Sign_In}
          </AuthButton>

          <NewMember>
            New to Nextivity? <TextButton onClick={() => showAccountInfo()}>Create an account</TextButton>
            .<br />
            <TextButton onClick={() => navigate("/forgot-password")}>Forgot password?</TextButton>
          </NewMember>
        </FormWrapper>
      )}

      {loginStep === LOGIN_STEP.MFA && (
        <FormWrapper onSubmit={handleSubmitMfa((data: FieldValues) => onSubmitMfa(data))}>
          <LogoImg src={LogoBlue} alt="logo" />
          <NewMember style={{ marginBottom: 15 }}>
            Please input the MFA code sent to your phone for verification.
          </NewMember>
          <FormInput
            placeholder="MFA Code"
            {...registerMfa("mfaCode", {
              required: "MFA Code is required!",
            })}
            error={mfaErrors.mfaCode}
          />
          {mfaErrors.mfaCode && <FieldError>{mfaErrors.mfaCode.message}</FieldError>}

          <AuthButton disabled={isMfaLoading} onClick={handleSubmitMfa(onSubmitMfa)} sx={{ mt: 2 }}>
            Submit
          </AuthButton>
        </FormWrapper>
      )}

      {loginStep === LOGIN_STEP.NEW_PASSWORD && (
        <FormWrapper onSubmit={handleSubmitNewPassword((data: FieldValues) => onSubmitNewPassword(data))}>
          <LogoImg src={LogoBlue} alt="logo" />
          <NewMember style={{ marginBottom: 15 }}>Please input new password.</NewMember>
          <FormInput
            placeholder="New Password"
            type="password"
            {...registerNewPassword("newPassword", {
              required: "New Password is required!",
              validate: {
                passwordStrengthCheck,
              },
            })}
            style={{ marginTop: 20 }}
            error={newPasswordErrors.newPassword}
          />
          {newPasswordErrors.newPassword && <FieldError>{newPasswordErrors.newPassword.message}</FieldError>}

          <FormInput
            placeholder="Confirm New Password"
            type="password"
            {...registerNewPassword("confirmNewPassword", {
              required: "Confirm Password is required!",
              validate: (value: string) => value === newPassword || "Passwords do not match!",
            })}
            style={{ marginTop: 20 }}
            error={newPasswordErrors.confirmNewPassword}
          />
          {newPasswordErrors.confirmNewPassword && (
            <FieldError>{newPasswordErrors.confirmNewPassword.message}</FieldError>
          )}

          <AuthButton disabled={isNewPwdLoading} onClick={handleSubmitNewPassword(onSubmitNewPassword)} sx={{ mt: 2 }}>
            Submit
          </AuthButton>
        </FormWrapper>
      )}

      <Dialog fullWidth maxWidth="sm" open={isOpenAccountModal} onClose={onModalClose} disableEscapeKeyDown>
        <DialogTitle>New Account</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <AccountInfo>
              If your organization already has an account, contact your organization’s administrator to create a user
              account.
              <br /> <br /> If your organization does not yet have an account, contact{" "}
              <a href="mailto:support@nextivityinc.com">support@nextivityinc.com</a> to create an administrative account
              for your organization. <br />
              <br />
              Please provide:
              <ul>
                <li>Organization name</li>
                <li>Your Name</li>
                <li>Mobile Phone Number</li>
                <li>Email</li>
              </ul>
            </AccountInfo>

            <PageFooter>
              <GhostButton onClick={() => setIsOpenAccountModal(false)}>{ButtonText.Close}</GhostButton>
            </PageFooter>
          </Box>
        </DialogContent>
      </Dialog>
    </PageContainer>
  );
};

export default Login;
