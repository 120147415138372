import React, { useMemo, useState } from "react";
import { useParams, useNavigate, Params } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { CircularProgress, Typography, useTheme } from "@mui/material";
import { ErrorScreen } from "components/common/error -screen/error-screen.component";
import { NxtIconButton } from "components/common/nxt-icon-button/nxt-icon-button.component";
import { PageSection, DetailWrapper, ActionBar } from "components/common/Wrapper";
import CustomTable from "components/table/table.component";
import LoadingSpinner from "components/LoadingSpinner/loading-spinner.component";
import { TableBodyCell } from "components/table/table.style";
import { useRequest } from "hooks/useRequest";
import { convertIpAddress, storeActiveSerial } from "utils/util-functions";
import DetailGridComponent from "./detail-grid.component";
import DetailForm from "./device-detail-form.component";
import { GridActionRow, SectionHeader, SpinnerWrapper } from "./DeviceDetail.style";
import PENDING_GRID_COLUMNS, { ACTION_JSON, PendingGridColumnsTypes } from "./device-detail.config";
import { Popup } from "../../../components/common/popup/popup.component";
import { updateVpnDeviceStatus } from "../../../api/DeviceHelpers";
import { showWarning } from "../../../utils/notifications";
import DetailActions from "./detail-actions.component";
import { DeviceDetailDataTypes } from "../devices.config";

export const VPN_CONNECTED = "Connected";
export const VPN_CONNECTION_FAILED = "Connection Failed";
export const VPN_ERROR = "Error";

export function DeviceDetailPage(): JSX.Element {
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
  const [isLoadingEiInterfaces, setIsLoadingEiInterfaces] = useState<boolean>(false);
  const [isControlLaunching, setIsControlLaunching] = useState<boolean>(false);
  const [isVpnInitialized, setIsVpnInitialized] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<string>("");
  const VPN_NOT_CONNECTED: string = "Not Connected";
  const { sn: serialNumber }: Readonly<Params<string>> = useParams();
  const navigate = useNavigate();

  const {
    data: detailData,
    isLoading: isGettingDetail,
    mutate: mutateDetail,
    error: detailError,
  } = useRequest(`eiStatusUIHook?serialNumber=${serialNumber}`);

  // Initially load vpn data and check if 'vpnStatus' is 'Connected'.
  const { data: vpnData } = useRequest(`vpnUIHook?serialNumber=${serialNumber}`);

  const vpnConnectionOnClose = () => {
    setIsControlLaunching(false);
  };

  const errorPopupOnClose = () => {
    setIsError(false);
  };

  if (isControlLaunching && vpnData.vpnStatus === VPN_CONNECTED) {
    if (serialNumber) {
      sessionStorage.setItem("serialNumber", serialNumber);
    }

    navigate(`/launch-mission-control/${serialNumber}`);
    setIsControlLaunching(false);
    setIsVpnInitialized(false);
  }

  if (
    isControlLaunching &&
    isVpnInitialized &&
    (vpnData.vpnStatus === VPN_CONNECTION_FAILED || vpnData.vpnStatus === VPN_ERROR)
  ) {
    if (vpnData.vpnStatus === VPN_CONNECTION_FAILED) {
      showWarning("VPN connection could not be established. Please try later or contact support.");
    } else if (vpnData.vpnStatus === VPN_ERROR) {
      showWarning(
        "VPN connection could not be established. Please check for errors in the device system log or contact support.",
      );
    }
    setIsControlLaunching(false);
    setIsVpnInitialized(false);
  }

  const deviceDetail = useMemo(() => {
    if (detailData && detailData?.deviceData) {
      return {
        ...detailData.deviceData[0],
        lanIpAddress: convertIpAddress(detailData.deviceData[0].lanIpAddress || ""),
      };
    }
    return null;
  }, [detailData]);

  const {
    data: pendingActions,
    isLoading: isLoadingActions,
    mutate: mutatePendingActions,
    error: pendingActionError,
  } = useRequest(`pendingActionsUIHook?serialNumber=${serialNumber}`);

  const pendingActionsData = useMemo(
    () =>
      pendingActions?.map((item: DeviceDetailDataTypes) => ({
        ...item,
        completed: item.completed ? "Yes" : "No",
      })),
    [pendingActions],
  );

  const goToLocation = () => {
    storeActiveSerial(serialNumber);
    navigate(`/map/${serialNumber}`);
  };

  const errorMessage = useMemo(() => {
    let message = "";

    if (!isLoadingActions && pendingActionError) {
      const { error: pendingErrorText } = pendingActionError.data;
      message += `${pendingErrorText}\n`;
    }

    if (!isGettingDetail && detailError) {
      const { error: detailErrorText } = detailError.data;
      message += detailErrorText;
    }

    return message ? `Errors occurred while calling the API:\n${message}` : null;
  }, [isLoadingActions, pendingActionError, isGettingDetail, detailError]);

  if (errorMessage) {
    return <ErrorScreen text={errorMessage} />;
  }

  const launchMissionControl = async () => {
    // If respoonse is 'Not Connected' call 'VPN Initialize API'.

    try {
      setIsControlLaunching(true);

      if ((vpnData.vpnStatus === VPN_NOT_CONNECTED || vpnData.vpnStatus === VPN_ERROR) && !isVpnInitialized) {
        await updateVpnDeviceStatus(serialNumber);
        setIsVpnInitialized(true);
      }

      if (vpnData.vpnStatus === VPN_CONNECTED) {
        setIsControlLaunching(false);
        setIsVpnInitialized(false);

        if (serialNumber) {
          sessionStorage.setItem("serialNumber", serialNumber);
        }

        navigate(`/launch-mission-control/${serialNumber}`);
      }
    } catch (error: any) {
      console.warn("vpnerror", error);
      setIsControlLaunching(false);
      setIsVpnInitialized(false);
      setIsError(true);
      setErrorText(error.message);
    }
  };

  const getColumnValue = (value: string | number, columnId: string) => {
    const actionValues: { value: string | number } = { value };

    // The pending action comes over as a long string. We break it up with the following
    if (columnId === ACTION_JSON && typeof actionValues.value === "string") {
      const regex = /"[^"\\]*(?:\\[\s\S][^"\\]*)*"/g;
      const actionsArray = actionValues.value.match(regex);
      const actionIndex = actionsArray?.indexOf("ei.action");
      const pendingAction = actionIndex && actionsArray && actionsArray[actionIndex + 2].replaceAll('"', "");

      return pendingAction;
    }
    return value;
  };

  return (
    <DetailWrapper>
      <ActionBar style={{ marginBottom: 0 }}>
        <NxtIconButton onClick={() => goToLocation()} text="Show Location" icon={<LocationOnIcon />} />
      </ActionBar>

      <PageSection>
        <DetailForm
          deviceDetail={deviceDetail}
          mutatePendingActions={mutatePendingActions}
          setIsLoadingDetail={setIsLoadingDetail}
          isLoadingDetail={isLoadingDetail || isGettingDetail}
          mutateDetail={mutateDetail}
        />
      </PageSection>

      <GridActionRow>
        <PageSection>
          <DetailGridComponent
            isLoadingDetail={isLoadingEiInterfaces || isGettingDetail}
            deviceDetail={deviceDetail}
            setIsLoadingDetail={setIsLoadingEiInterfaces}
            mutateDetail={mutateDetail}
          />

          {isGettingDetail && (
            <SpinnerWrapper>
              <LoadingSpinner />
            </SpinnerWrapper>
          )}
        </PageSection>

        <DetailActions
          launchMissionControl={launchMissionControl}
          deviceDetail={deviceDetail}
          mutatePendingActions={mutatePendingActions}
          mutateDetail={mutateDetail}
        />
      </GridActionRow>

      <PageSection>
        <SectionHeader>
          <span className="header-title">Pending Updates</span>
        </SectionHeader>

        <CustomTable>
          <CustomTable.Header columns={PENDING_GRID_COLUMNS} />

          <CustomTable.Body isLoading={isLoadingActions} deviceData={pendingActionsData}>
            {pendingActionsData?.map((row: DeviceDetailDataTypes, i: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableRow key={`${row.eiSerialNumber}${i}`}>
                {PENDING_GRID_COLUMNS.map((column: PendingGridColumnsTypes) => {
                  const value = getColumnValue(row[column.id as keyof DeviceDetailDataTypes], column.id);

                  return (
                    <TableBodyCell key={column.id} align={column.align} style={{ color: "#656D78" }}>
                      {column.format && typeof value === "number" ? column.format(value) : value}
                    </TableBodyCell>
                  );
                })}
              </TableRow>
            ))}
          </CustomTable.Body>
        </CustomTable>
      </PageSection>

      <Popup
        title="Establishing VPN connection..."
        isOpen={isControlLaunching}
        primaryBtn={{ onClick: vpnConnectionOnClose, text: "Cancel" }}
      >
        <Typography>Note: This process may take up to a minute or longer.</Typography>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "32px" }}>
          <CircularProgress size={60} variant="indeterminate" />
        </div>
      </Popup>

      {isError && (
        <Popup title="Error" isOpen={isError} primaryBtn={{ onClick: errorPopupOnClose, text: "Cancel" }}>
          <Typography>{errorText}</Typography>
        </Popup>
      )}
    </DetailWrapper>
  );
}
