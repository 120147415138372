import React, { useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { Box } from "@mui/material";
import { UserColumnTypes } from "pages/configs/configs";
import { onEnter } from "utils/general";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import ActionBarSelect from "components/action-bar-select/action-bar-select";
import { FormInput } from "components/common/Input";
import { ButtonText, PrimaryButton, TextButton } from "components/common/Buttons";
import { getDateString } from "utils/util-functions";
import { COLUMN_TYPE } from "utils/constants";
import { DateFilterWrapper, FilterWrapper } from "./table.style";
import style from "./table-filter-new.module.css";

interface TableFilterNewProps {
  columns: UserColumnTypes[];
  onSearch: (...args: any) => void;
  onReset: () => void;
}

const TableFilterNew = ({ columns, onSearch, onReset }: TableFilterNewProps) => {
  const [activeFilter, setActiveFilter] = useState<string>("");
  const [startDate, setStartDate] = useState<dayjs.ConfigType>(dayjs(new Date()));
  const [endDate, setEndDate] = useState<dayjs.ConfigType>(dayjs(new Date()));

  const isValidFilter = useMemo(() => !!activeFilter, [activeFilter]);
  const columnOptions = useMemo(() => columns?.map(({ id, label }) => ({ value: id, label })) || [], [columns]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
  }: FieldValues = useForm();
  const columnField: string = watch("column", "");

  const selectedColumn = useMemo(() => {
    const selected = columns.find(({ id }) => id === columnField);
    return selected || columns?.[0];
  }, [columnField]);

  const onSearchClick = ({ option, searchWord: word }: { option: string; searchWord: string }) => {
    const { id, type } = selectedColumn;

    if (type === COLUMN_TYPE.STRING || type === COLUMN_TYPE.NUMBER) {
      onSearch(id, type, word);
      setActiveFilter(`${selectedColumn.label} = ${word}`);
    }

    if (type === COLUMN_TYPE.OPTION) {
      onSearch(id, type, option);
      setActiveFilter(`${selectedColumn.label} = ${option}`);
    }
    if (type === COLUMN_TYPE.DATE) {
      onSearch(id, type, {
        startDate,
        endDate,
      });
      setActiveFilter(`${selectedColumn.label} = ${getDateString(startDate)} ~ ${getDateString(endDate)}`);
    }
  };

  const onResetFilter = () => {
    setActiveFilter("");
    onReset?.();
    reset((formValues: FieldValues) => ({ ...formValues, searchWord: "" }));
  };

  return (
    <FilterWrapper>
      <Box className={style.filterContainer}>
        <Box className={style.selectContainer}>
          <ActionBarSelect
            actionName="column"
            options={columnOptions}
            control={control}
            rules={{ required: "Column field is required" }}
          />
        </Box>
        {selectedColumn.type === COLUMN_TYPE.OPTION && (
          <Box className={style.selectOptions}>
            <ActionBarSelect actionName="option" options={selectedColumn.options || []} control={control} />
          </Box>
        )}
        {selectedColumn.type === COLUMN_TYPE.STRING && (
          <Box className={style.formInputContainer}>
            <FormInput
              onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmit(onSearchClick))}
              {...register("searchWord", { required: "Please enter text to search!" })}
              placeholder="Search by attribute"
              error={errors.searchWord}
            />
            {errors.searchWord && <span className="error-text">{errors.searchWord.message}</span>}
          </Box>
        )}
        {selectedColumn.type === COLUMN_TYPE.NUMBER && (
          <Box className={style.formInputContainer}>
            <FormInput
              onKeyDown={(event: KeyboardEvent) => onEnter(event, handleSubmit(onSearchClick))}
              {...register("searchWord", { required: "Please enter number to search!" })}
              placeholder="Search by attribute"
              error={errors.searchWord}
            />
            {errors.searchWord && <span className="error-text">{errors.searchWord.message}</span>}
          </Box>
        )}
        {selectedColumn.type === COLUMN_TYPE.DATE && (
          <DateFilterWrapper>
            <span className={style.dateTimeMargin}>From:</span>
            <DateTimePicker value={startDate} onChange={(newValue) => setStartDate(newValue)} />
            <span className={style.dateTimeMargin}>To:</span>
            <DateTimePicker value={endDate} onChange={(newValue) => setEndDate(newValue)} />
          </DateFilterWrapper>
        )}

        <div className={style.searchButtonMargin}>
          <PrimaryButton onClick={handleSubmit(onSearchClick)}>{ButtonText.Search}</PrimaryButton>
        </div>

        {isValidFilter && (
          <div className={style.resetButtonMargin}>
            <TextButton onClick={onResetFilter}>Reset Filter</TextButton>
          </div>
        )}
      </Box>
    </FilterWrapper>
  );
};

export default TableFilterNew;
