import React, { BaseSyntheticEvent, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { FieldValues, useForm } from "react-hook-form";
import { useTheme } from "@mui/material";
import EastIcon from "@mui/icons-material/East";

import CustomTable from "components/table/table.component";
import { CommonSelect } from "components/common/common-select/common-select.component";
import { FieldError } from "components/common/Input";
import { HeaderTitle } from "components/common/Text";
import { DetailWrapper, PageSection, PageHeader, PageFooter } from "components/common/Wrapper";
import { TableColumnTypes } from "pages/configs/configs";
import { PrimaryButton, GhostButton, ButtonText } from "components/common/Buttons";
import { showError, showSuccess } from "utils/notifications";
import { updateDevice } from "api/DeviceHelpers";
import { TableBodyCell } from "components/table/table.style";
import { useRequest } from "hooks/useRequest";
import POOL_DEVICE_COLS from "./pool-detail.config";
import { DevicePoolTypes, PoolDeviceListTypes, PoolDeviceTypes } from "../pools.config";

const PoolDetail = (): JSX.Element => {
  const { poolId } = useParams();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [currentRow, setCurrentRow] = useState<PoolDeviceTypes | null>(null);
  const [isTransforming, setIsTransforming] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const [isOpenTransferModal, setIsOpenTransferModal] = useState<boolean>(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const startIndex = useMemo(() => rowsPerPage * page, [rowsPerPage, page]);
  const endIndex = useMemo(() => startIndex + rowsPerPage - 1, [startIndex, rowsPerPage]);
  const { data, isLoading, mutate } = useRequest(
    `devices?devicePool=${poolId}&firstDeviceIndex=${startIndex}&lastDeviceIndex=${endIndex}`,
  );
  const totalDeviceCount = useMemo(() => data?.totalDevicesInPool || 0, [data]);
  const deviceList: PoolDeviceTypes[] = useMemo(() => data?.deviceData || [], [data]);

  const { data: poolsData } = useRequest("devicePools");

  const poolList: DevicePoolTypes[] = useMemo(
    () => (poolsData?.length > 0 ? poolsData.filter(({ devicePool }: DevicePoolTypes) => devicePool !== poolId) : []),
    [poolsData, poolId],
  );
  const poolOptions = useMemo(
    () => poolList.map(({ devicePool }: DevicePoolTypes) => ({ value: devicePool, label: devicePool })),
    [poolList],
  );

  const bodyColumns = useMemo(() => {
    const tempCols = [...POOL_DEVICE_COLS];
    tempCols.pop();
    return tempCols;
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
  }: FieldValues = useForm();

  const onClickMenu = (event: BaseSyntheticEvent, row: PoolDeviceTypes) => {
    setAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentRow(null);
  };

  const transferDevice = () => {
    setAnchorEl(null);
    setIsOpenTransferModal(true);
  };

  const onModalClose = (event: never, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason !== "backdropClick") {
      setIsOpenTransferModal(false);
    }
  };

  const onConfirmTransfer = async ({ devicePool }: FieldValues): Promise<void> => {
    if (!devicePool) {
      showError("Device pool is not valid!");
      return;
    }

    if (!currentRow) {
      return;
    }

    setIsTransforming(true);

    try {
      // call PUT /devices API
      const result = await updateDevice({
        serialNumber: currentRow.serialNumber,
        devicePool,
      });

      if (result.data) {
        // close modal & show alert
        setIsOpenTransferModal(false);
        showSuccess(`Success to transfer a device ${currentRow.serialNumber} to ${devicePool} pool!`);

        // Refresh table
        mutate();
      }
    } catch (error) {
      // error handling
    } finally {
      setIsTransforming(false);
    }
  };

  const onClickSN = (serialNumber: string) => {
    navigate(`/devices/${serialNumber}`);
  };

  return (
    <DetailWrapper>
      <PageSection>
        <PageHeader>
          <HeaderTitle>{`Devices in Pool '${poolId}'`}</HeaderTitle>
        </PageHeader>

        <CustomTable
          rowsPerPageOptions={[10, 25, 100]}
          totalCount={totalDeviceCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={(pageNum) => setPage(pageNum)}
          onRowsPerPageChange={(rowPerPage) => setRowsPerPage(rowPerPage)}
        >
          <CustomTable.Header columns={POOL_DEVICE_COLS} />

          <CustomTable.Body isLoading={isLoading} deviceData={deviceList}>
            {deviceList &&
              deviceList.map((row: PoolDeviceTypes) => (
                <TableRow hover key={row.serialNumber}>
                  {bodyColumns.map((column: TableColumnTypes) => {
                    const value = row[column.id as keyof PoolDeviceTypes];

                    return (
                      <TableBodyCell
                        key={column.id}
                        onClick={() => onClickSN(typeof value === "string" ? row.serialNumber : "")}
                      >
                        <span>{value}</span>
                      </TableBodyCell>
                    );
                  })}

                  <TableBodyCell style={{ width: 30 }}>
                    <Button onClick={(e) => onClickMenu(e, row)} style={{ color: theme.palette.common.black }}>
                      <MoreVertIcon />
                    </Button>
                  </TableBodyCell>
                </TableRow>
              ))}
          </CustomTable.Body>
        </CustomTable>
      </PageSection>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={transferDevice}>Transfer</MenuItem>
      </Menu>

      <Dialog fullWidth maxWidth="xs" open={isOpenTransferModal} onClose={onModalClose} disableEscapeKeyDown>
        <DialogTitle>Transfer Device</DialogTitle>
        <DialogContent>
          <div>{`Please select a pool to transfer device '${currentRow?.serialNumber}'.`}</div>

          <form>
            <CommonSelect
              name="devicePool"
              options={poolOptions}
              control={control}
              rules={{ required: "Device Pool is required" }}
              error={errors.devicePool}
            />
            {errors.devicePool && <FieldError>{errors.devicePool.message}</FieldError>}

            <PageFooter>
              <GhostButton onClick={() => setIsOpenTransferModal(false)}>{ButtonText.Cancel}</GhostButton>
              <PrimaryButton disabled={isTransforming} onClick={handleSubmit(onConfirmTransfer)}>
                {ButtonText.Transfer}
              </PrimaryButton>
            </PageFooter>
          </form>
        </DialogContent>
      </Dialog>
    </DetailWrapper>
  );
};

export default PoolDetail;
