import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import PhoneInput from "react-phone-number-input";

export const FormInput = styled(OutlinedInput)`
  width: 100%;
  height: 33px;

  input {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 14px;
    text-align: start;
  }
`;

export const StyledTextField = styled(TextField)`
  margin-top: 16px;
  width: 100%;
  padding-bottom: 16px;

  & .MuiOutlinedInput-notchedOutline {
    border-color: #ccd1d9;
  }

  & .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #4faee0;
  }

  & .Mui-focused.MuiInputLabel-outlined {
    color: #4faee0;
  }

  & .MuiFormLabel {
    color: #4faee0;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background: none;
    background-color: none;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  & .MuiSelect-icon {
    fill: var(--mega-color-gray);
  }

  & .Mui-focused .MuiSelect-icon {
    fill: var(--mega-color-primary);
  }

  & .MuiSelect-select {
    padding-top: 6px;
    padding-bottom: 6px;
  }
`;

export const FieldError = styled.span`
  font-size: 15px;
  margin-bottom: 20px;
  color: #d73c23;
  width: 100%;
  white-space: pre-line;
`;

export const PhoneInputWrapper = styled(PhoneInput)`
  width: 100%;
  input {
    height: 23px;
    padding: 16.5px 14px;
    color: #434a54;
    font-family: var(--mega-font-family);
    font-size: 14px;
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin-bottom: 0;
  }
`;

export const PhoneFormInputWrapper = styled(PhoneInput)`
  width: 100%;
  margin-top: 16px;
  input {
    height: 23px;
    padding: 16.5px 14px;
    color: #434a54;
    font-family: var(--mega-font-family);
    font-size: 14px;
    width: 100%;
    border: 0;
    border-radius: 5px;
    margin-bottom: 0;
  }
`;
