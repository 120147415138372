import React, { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { FieldError, PhoneFormInputWrapper, PhoneInputWrapper, StyledTextField } from "components/common/Input";
import { emailRegex } from "utils/constants";
import { FieldValues, useForm, useFormState } from "react-hook-form";
import { nameValidationCheck } from "utils/util-functions";
import { OptionTypes } from "pages/configs/configs";
import { PageFooter } from "components/common/Wrapper";
import { ButtonText, GhostButton, PrimaryButton } from "components/common/Buttons";
import { createNewUser } from "api/AdminHelpers";
import { showSuccess } from "utils/notifications";
import { CommonSelect } from "components/common/common-select/common-select.component";
import CustomPhoneNumber from "../common/phone-input/phone-input.component";

interface NewAdminDialogProps {
  open: boolean;
  orgList?: OptionTypes[];
  userId: string;
  selectedOrg?: string;
  onClose: () => void;
  onAdd: () => void;
}

const NewAdminDialog = ({ open, orgList, userId, selectedOrg, onClose, onAdd }: NewAdminDialogProps): JSX.Element => {
  const [phoneNum, setPhoneNum] = useState<string>("");
  const [isCreatingNewAdmin, setIsCreatingNewAdmin] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  }: FieldValues = useForm();
  const formState = useFormState({ control });

  useEffect(() => {
    setValue("adminName", "");
    setValue("groupName", selectedOrg ?? "");
    setValue("email", "");
    setPhoneNum("");
  }, []);

  useEffect(() => {
    setValue("groupName", selectedOrg ?? "");
  }, [selectedOrg]);

  const onModalClose = (event: never, reason: string) => {
    if (reason !== "backdropClick") {
      onClose();
    }
  };

  const onCreateNewAdmin = async ({ adminName, email, groupName }: Record<string, string>) => {
    if (!phoneNum || !isPossiblePhoneNumber(phoneNum)) return;

    setIsCreatingNewAdmin(true);
    try {
      const { data } = await createNewUser({
        username: userId,
        groupname: groupName,
        name: adminName,
        phonenumber: phoneNum,
        email: email,
        role: "admin",
      });
      showSuccess(data?.message);

      onAdd();
      onClose();
    } catch (error) {
      // error handling
    } finally {
      setIsCreatingNewAdmin(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onModalClose} disableEscapeKeyDown>
      <DialogTitle>New Admin</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <StyledTextField
            label="Admin Name"
            {...register("adminName", {
              required: "Admin Name is required",
              validate: {
                nameValidationCheck,
              },
            })}
            error={errors.adminName}
          />

          {errors.adminName && <FieldError>{errors.adminName.message}</FieldError>}

          {!selectedOrg && (
            <CommonSelect
              name="groupName"
              label="Group Name"
              options={orgList || []}
              control={control}
              rules={{ required: "Organization is required" }}
              error={errors.groupName}
            />
          )}
          {errors.groupName && <FieldError>{errors.groupName.message}</FieldError>}

          <StyledTextField
            label="Email"
            {...register("email", {
              required: "Email is required!",
              pattern: {
                value: emailRegex,
                message: "Entered value does not match email format!",
              },
            })}
            error={errors.email}
          />

          {errors.email && <FieldError>{errors.email.message}</FieldError>}

          <PhoneFormInputWrapper
            international
            inputComponent={CustomPhoneNumber}
            defaultCountry="US"
            countryCallingCodeEditable={false}
            value={phoneNum}
            onChange={(e: string) => {
              setPhoneNum(e);
            }}
          />
          {formState?.submitCount > 0 && (
            <FieldError>
              {phoneNum
                ? isPossiblePhoneNumber(phoneNum)
                  ? undefined
                  : "Phone number is not valid!"
                : "Phone number is required!"}
            </FieldError>
          )}

          <PageFooter>
            <GhostButton onClick={onClose}>{ButtonText.Cancel}</GhostButton>
            <PrimaryButton onClick={handleSubmit(onCreateNewAdmin)} disabled={isCreatingNewAdmin}>
              {ButtonText.Create}
            </PrimaryButton>
          </PageFooter>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default NewAdminDialog;
